// @flow
import React, { useState, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { AuthClient } from 'clients/auth'
import { I18n } from 'react-i18nify'
import idx from 'idx'
import isEmpty from 'lodash/isEmpty'
import * as queryString from 'query-string'
import { useMutation } from 'react-apollo-hooks'
import { useSignupData } from 'components/Signup/SignupData'
import { resetVisit, trackSignup, heapTrack } from 'utilities/analytics'
import { setToken } from 'utilities/token'
import { SIGN_UP_NEW_USER, SignupError } from './mutations'
import View from './View'

import type { NavigateFn } from '@reach/router'
import type { signUpNewUser as SignUpNewUserT } from './__generated__/signUpNewUser'

type Props = {
  navigate?: NavigateFn,
  location?: {
    search?: string,
    state?: {
      uid: string,
      headerI18n?: string,
    },
  },
  merchantHandle?: string,
  programIdentifier?: string,
}

const translate = (key: string) => I18n.t(`pages.welcome.${key}`)

const Welcome = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [error, setError] = useState<?string>(null)

  const signUpNewUser = useMutation(SIGN_UP_NEW_USER)

  const { signupData, setSignupData } = useSignupData()
  const { merchant, program } = signupData
  const isEarnByCheckin = merchant.earnLoyaltyType === 'check_in'
  const isCardRequired = idx(program, _ => _.isCardRequired)

  const search = idx(props, _ => _.location.search)
  const { signup_code: signupCode } = search ? queryString.parse(search) : {}

  useEffect(() => {
    let timer
    if (isSuccess) {
      timer = setTimeout(() => {
        onSignupSuccess(props)
        setTimeout(() => setIsSuccess(false), 50)
      }, 750)
    }
    return () => clearTimeout(timer)
  }, [isSuccess, setIsSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // TODO: https://thanxapp.atlassian.net/browse/TA-26094
    // merchant handle should be a global property
    heapTrack('Page Presented', {
      Page: 'Initial',
      MerchantHandle: merchant.handle,
    })
  }, [merchant, signupData])

  const onSignupSuccess = (props: Props) => {
    if (isEarnByCheckin) {
      if (props.navigate) props.navigate('info')
    } else if (isCardRequired) {
      if (props.navigate) props.navigate('progress')
    } else {
      if (props.navigate) props.navigate('benefits')
    }
  }

  const onSubmit = async fields => {
    if (!program) return

    setIsLoading(true)
    setError(null)

    let result
    try {
      result = await signUpNewUser({
        variables: {
          email: fields.email,
          programId: program.id,
          uid: idx(props, _ => _.location.state.uid),
          // $FlowFixMe meh
          referrerId: idx(program, _ => _.referrer.id),
          signupCode: signupCode,
        },
        errorPolicy: 'all',
      })
    } catch (error) {
      // TODO: Figure out why errorPolicy all still throws network errors
      setIsLoading(false)
      setError(I18n.t('errors.network'))
      return
    }

    const { data, error }: { data: SignUpNewUserT, error: Object } = result

    setIsLoading(false)

    // Graphql errors
    if (error) {
      if (error.networkError) {
        setError(I18n.t('errors.network'))
      } else {
        setError(translate('errors.generic'))
      }
      return
    }

    // Application errors
    const errors = idx(data, _ => _.signUpNewUser.errors)
    if (!isEmpty(errors)) {
      if (errors.length != 1) {
        setError(setError(translate('errors.generic')))
      }

      const errorStatus = errors[0].status
      // Handle error user already exists
      if (errorStatus === SignupError.USER_EXISTS) {
        if (!props.merchantHandle || !props.programIdentifier) {
          // Should never happen
          throw 'Missing merchant handle or program identifier'
        }

        const authClient = new AuthClient()
        const authResponse = await authClient.authorize(
          fields.email,
          props.merchantHandle,
          props.programIdentifier
        )
        if (authResponse.ok && props.navigate) {
          props.navigate('passwordless-email')
        } else {
          Sentry.captureException(
            new Error(`Invalid auth response. Status: ${authResponse.status}`)
          )
          setError(translate('errors.generic'))
        }
      } else if (errorStatus === SignupError.EMAIL_INVALID) {
        setError(translate('errors.invalid'))
      } else {
        setError(translate('errors.generic'))
      }
      return
    }

    const user = idx(data, _ => _.signUpNewUser.user)
    const token = idx(data, _ => _.signUpNewUser.token)

    setToken(token)
    setSignupData(state => ({
      ...state,
      user,
    }))

    resetVisit()
    trackSignup(idx(user, _ => _.email) || '')

    setIsSuccess(true)
  }

  return (
    <View
      merchant={merchant}
      program={program}
      error={error}
      headerI18n={idx(props, _ => _.location.state.headerI18n)}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isPasswordless={!!(merchant.app && merchant.app.isPasswordless)}
      onSubmit={onSubmit}
      onInvalid={() => setError(null)}
      onValid={() => setError(null)}
    />
  )
}

export default Welcome
