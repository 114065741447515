// @flow
//
// These are the global data loading queries.
// Because our app doesn't require much data,
// we load all of the data up front to minimize
// # of requests over payload size. If this becomes
// too slow, we can have each screen load its own data
// instead.
import { gql } from 'apollo-boost'
import { graphql } from 'gatsby'

// TODO: Gatsby doesn't support string interpolation in its graphql queries.
// This needs to be the same as the apollo MerchantFields fragment below
export const GATSBY_MERCHANT_FRAGMENT = graphql`
  fragment MerchantFields on ThanxApi_Merchant {
    id
    isBranded
    handle
    name
    logoImage(size: "two_x")
    coverImage(size: "three_x_dark")
    earnLoyaltyType
    app {
      id
      isPasswordless
      primaryColor
      handle
      name
      secondaryImage(size: "standard")
      strings {
        signupWelcome
      }
      urls {
        appStore
        googlePlay
      }
      oauthClientId
    }
    locations {
      street
      city
      state
      name
      isComingSoon
      tagValue
    }
    introProgram {
      ...ProgramFields
    }
    smsMarketingEnabled
  }
`

export const APOLLO_MERCHANT_FRAGMENT = gql`
  fragment MerchantFields on Merchant {
    id
    isBranded
    handle
    name
    logoImage(size: "two_x")
    coverImage(size: "three_x_dark")
    earnLoyaltyType
    app {
      id
      isPasswordless
      primaryColor
      handle
      name
      secondaryImage(size: "standard")
      strings {
        signupWelcome
      }
      urls {
        appStore
        googlePlay
      }
      oauthClientId
    }
    locations {
      street
      city
      state
      name
      isComingSoon
      tagValue
    }
    introProgram {
      ...ProgramFields
    }
    smsMarketingEnabled
  }
`

// TODO: Gatsby doesn't support string interpolation in its graphql queries.
// This needs to be the same as the apollo ProgramFields fragment below.
// We can ignore the Invite part since that program is always dynamically loaded.
export const GATSBY_PROGRAM_FRAGMENT = graphql`
  fragment ProgramFields on ThanxApi_Program {
    id
    isCardRequired
    earn {
      id
      type
    }
    redeem {
      id
      description
      percent
    }
  }
`

export const APOLLO_PROGRAM_FRAGMENT = gql`
  fragment ProgramFields on Program {
    id
    earn {
      id
      type
    }
    isCardRequired
    redeem {
      id
      description
      percent
    }
    ... on Invite {
      referrer {
        firstName
        lastName
        id
      }
    }
  }
`

export const USER_FRAGMENT = gql`
  fragment UserFields on User {
    id
    email
    isSms
    firstName
    lastName
    phone
    state
    cards {
      id
    }
  }
`

export const MERCHANT_QUERY = gql`
  query MerchantQuery($merchantHandle: String!) {
    merchant(handle: $merchantHandle) {
      ...MerchantFields
    }
  }
  ${APOLLO_MERCHANT_FRAGMENT}
  ${APOLLO_PROGRAM_FRAGMENT}
`

export const PROGRAM_QUERY = gql`
  query ProgramQuery($merchantHandle: String!, $identifier: String!) {
    merchant(handle: $merchantHandle) {
      id
      program(identifier: $identifier) {
        ...ProgramFields
      }
    }
  }
  ${APOLLO_PROGRAM_FRAGMENT}
`

export const USER_QUERY = gql`
  query UserQuery {
    user {
      ...UserFields
    }
  }
  ${USER_FRAGMENT}
`
